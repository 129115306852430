export const tyreAgricultureTableField = [
  {
    text: 'langkey.size',
    value: 'size',
    minWidth: {
      desktop: '95px',
      desktopLg: '95px',
    },
    maxWidth: {
      desktop: '1fr',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.tube-type-tubeless',
    value: 'tubeType',
    minWidth: {
      desktop: '40px',
      desktopLg: '40px',
    },
    maxWidth: {
      desktop: '40px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.load-index',
    value: 'loadIndex',
    sortable: true,
    minWidth: {
      desktop: '40px',
      desktopLg: '40px',
    },
    maxWidth: {
      desktop: '40px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.speed-index',
    value: 'speedIndex',
    sortable: true,
    minWidth: {
      desktop: '32px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '32px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.manufacturer',
    value: 'manufacturer',
    sortable: true,
    minWidth: {
      desktop: '90px',
      desktopLg: '90px',
    },
    maxWidth: {
      desktop: '90px',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.profile',
    value: 'profile',
    sortable: true,
    minWidth: {
      desktop: '150px',
      desktopLg: '150px',
    },
    maxWidth: {
      desktop: '150px',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.ply-rating',
    value: 'plyRating',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '44px',
      desktopLg: '50px',
    },
    maxWidth: {
      desktop: '44px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.prefix',
    value: 'prefix',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '60px',
      desktopLg: '64px',
    },
    maxWidth: {
      desktop: '60px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.usage',
    value: 'usage',
    sortable: true,
    minWidth: {
      desktop: '80px',
      desktopLg: '80px',
    },
    maxWidth: {
      desktop: '80px',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.list-price',
    align: 'center',
    value: 'prices',
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
  {
    text: '%',
    align: 'center',
    value: 'discount',
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.pp',
    align: 'center',
    value: 'purchasePrice',
    sortable: true,
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.shipment-type',
    align: 'center',
    value: 'expressAvailable',
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.offer',
    align: 'center',
    value: 'data-table-expand',
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
];

export const tyreAgricultureRetailPriceTableField = [
  {
    text: 'langkey.size',
    value: 'size',
    minWidth: {
      desktop: '95px',
      desktopLg: '95px',
    },
    maxWidth: {
      desktop: '1fr',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.tube-type-tubeless',
    value: 'tubeType',
    minWidth: {
      desktop: '40px',
      desktopLg: '40px',
    },
    maxWidth: {
      desktop: '40px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.load-index',
    value: 'loadIndex',
    sortable: true,
    minWidth: {
      desktop: '40px',
      desktopLg: '40px',
    },
    maxWidth: {
      desktop: '40px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.speed-index',
    value: 'speedIndex',
    sortable: true,
    minWidth: {
      desktop: '32px',
      desktopLg: '32px',
    },
    maxWidth: {
      desktop: '32px',
      desktopLg: '70px',
    },
  },
  {
    text: 'langkey.manufacturer',
    value: 'manufacturer',
    sortable: true,
    minWidth: {
      desktop: '90px',
      desktopLg: '90px',
    },
    maxWidth: {
      desktop: '90px',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.profile',
    value: 'profile',
    sortable: true,
    minWidth: {
      desktop: '150px',
      desktopLg: '150px',
    },
    maxWidth: {
      desktop: '150px',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.ply-rating',
    value: 'plyRating',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '44px',
      desktopLg: '50px',
    },
    maxWidth: {
      desktop: '44px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.prefix',
    value: 'prefix',
    align: 'center',
    sortable: true,
    minWidth: {
      desktop: '60px',
      desktopLg: '64px',
    },
    maxWidth: {
      desktop: '60px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.usage',
    value: 'usage',
    sortable: true,
    minWidth: {
      desktop: '80px',
      desktopLg: '80px',
    },
    maxWidth: {
      desktop: '80px',
      desktopLg: '1fr',
    },
  },
  {
    text: 'langkey.netto',
    align: 'center',
    value: 'retailPrice',
    sortable: true,
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.brutto',
    align: 'center',
    value: 'retailTaxPrice',
    sortable: true,
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
  {
    text: '',
    align: 'center',
    value: 'offer',
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
  {
    text: 'langkey.shipment-type',
    align: 'center',
    value: 'expressAvailable',
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
  {
    text: '',
    align: 'center',
    value: 'data-table-expand',
    minWidth: {
      desktop: '50px',
      desktopLg: '56px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '75px',
    },
  },
];

export const tyreAgricultureTableFieldExpand = [
  { text: 'langkey.supplier', value: 'supplier', width: `${(4/18) * 100}%` },
  { text: 'langkey.stock', value: 'stock', width: `${(2/18) * 100}%` },
  { text: 'langkey.list-price', value: 'listPrice', width: `${(2/18) * 100}%` },
  { text: 'langkey.discount', value: 'discount', width: `${(2/18) * 100}%` },
  { text: 'langkey.price', value: 'purchasePrice', width: `${(2/18) * 100}%` },
  { text: 'langkey.quantity', align: 'center', value: 'qty', width: `${(4/18) * 100}%` },
  { text: '', align: 'center', value: 'expressShipmentAvailable', width: `${(1/18) * 100}%` },
  { text: '', align: 'right', value: 'order', width: `${(1/18) * 100}%` },
];

export const tyreAgricultureRetailPriceTableFieldExpand = [
  { text: 'langkey.supplier', value: 'supplier', width: `${(4/18) * 100}%` },
  { text: 'langkey.stock', value: 'stock', width: `${(2/18) * 100}%` },
  { text: 'langkey.netto', value: 'retailPrice', width: `${(3/18) * 100}%` },
  { text: 'langkey.brutto', value: 'retailTaxPrice', width: `${(3/18) * 100}%` },
  { text: 'langkey.quantity', align: 'center', value: 'qty', width: `${(4/18) * 100}%` },
  { text: '', align: 'center', value: 'expressShipmentAvailable', width: `${(1/18) * 100}%` },
  { text: '', align: 'right', value: 'order', width: `${(1/18) * 100}%` },
];

export const selectFilterFields = [
  {
    key: 'width',
    filterKey: 'widths',
    placeholder: '',
    singleSelectLabel: 'langkey.width',
    pluralSelectLabel: 'langkey.widths',
    single: true,
    required: true,
  },
  {
    key: 'aspectRatio',
    filterKey: 'aspectRatios',
    placeholder: '',
    singleSelectLabel: 'langkey.height',
    pluralSelectLabel: 'langkey.heights',
    single: true,
    required: true,
    requiredValueBefore: ['widths'],
  },
  {
    key: 'diameter',
    filterKey: 'diameters',
    placeholder: '',
    singleSelectLabel: 'langkey.diameter',
    pluralSelectLabel: 'langkey.diameters',
    single: true,
    required: true,
    requiredValueBefore: ['widths', 'aspectRatios'],
  },
  {
    key: 'loadIndex',
    filterKey: 'loadIndexes',
    placeholder: '',
    singleSelectLabel: 'langkey.load-index',
    pluralSelectLabel: 'langkey.load-indexes',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    searchOnSelect: true,
  },
  {
    key: 'speedIndex',
    filterKey: 'speedIndexes',
    placeholder: '',
    singleSelectLabel: 'langkey.speed-index',
    pluralSelectLabel: 'langkey.speed-indexes',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    searchOnSelect: true,
  },
  {
    key: 'tubeType',
    filterKey: 'tubeType',
    placeholder: '',
    singleSelectLabel: 'langkey.tube-type-tubeless',
    pluralSelectLabel: 'langkey.tube-type-tubeless',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    searchOnSelect: true,
    sortable: true,
    sortFunction: (a, b) => a.label.toString().length - b.label.toString().length,
  },
  {
    key: 'manufacturer',
    filterKey: 'manufacturers',
    placeholder: '',
    singleSelectLabel: 'langkey.manufacturer',
    pluralSelectLabel: 'langkey.manufacturers',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    searchOnSelect: true,
  },
  {
    key: 'profile',
    filterKey: 'profile',
    placeholder: '',
    singleSelectLabel: 'langkey.profile',
    pluralSelectLabel: 'langkey.profiles',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    searchOnSelect: true,
  },
  {
    key: 'quantity',
    filterKey: 'minStock',
    placeholder: '',
    singleSelectLabel: 'langkey.quantity',
    pluralSelectLabel: 'langkey.quantities',
    single: true,
    isUseLabelInSelectedValue: true,
    searchOnSelect: true,
    isSearchSubmit: true,
    customOptions: [
      {
        label: 'langkey.stockmorethan2',
        value: 2,
      },
      {
        label: 'langkey.stockmorethan4',
        value: 4,
      },
      {
        label: 'langkey.stockmorethan6',
        value: 6,
      },
      {
        label: 'langkey.stockmorethan8',
        value: 8,
      },
      {
        label: 'langkey.stockmorethan10',
        value: 10,
      },
    ],
  },
  {
    key: 'usage',
    filterKey: 'usage',
    placeholder: '',
    singleSelectLabel: 'langkey.usage',
    pluralSelectLabel: 'langkey.usages',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    single: true,
    searchOnSelect: true,
    isUseLabelInSelectedValue: true,
  },
];

export const selectFilterFieldsDisabled = [
  {
    key: 'width',
    filterKey: 'widths',
    placeholder: '',
    singleSelectLabel: 'langkey.width',
    pluralSelectLabel: 'langkey.widths',
    single: true,
    required: true,
    disabled: true,
  },
  {
    key: 'aspectRatio',
    filterKey: 'aspectRatios',
    placeholder: '',
    singleSelectLabel: 'langkey.height',
    pluralSelectLabel: 'langkey.heights',
    single: true,
    required: true,
    requiredValueBefore: ['widths'],
    disabled: true,
  },
  {
    key: 'diameter',
    filterKey: 'diameters',
    placeholder: '',
    singleSelectLabel: 'langkey.diameter',
    pluralSelectLabel: 'langkey.diameters',
    single: true,
    required: true,
    requiredValueBefore: ['widths', 'aspectRatios'],
    disabled: true,
  },
  {
    key: 'loadIndex',
    filterKey: 'loadIndexes',
    placeholder: '',
    singleSelectLabel: 'langkey.load-index',
    pluralSelectLabel: 'langkey.load-indexes',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    searchOnSelect: true,
  },
  {
    key: 'speedIndex',
    filterKey: 'speedIndexes',
    placeholder: '',
    singleSelectLabel: 'langkey.speed-index',
    pluralSelectLabel: 'langkey.speed-indexes',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    searchOnSelect: true,
  },
  {
    key: 'tubeType',
    filterKey: 'tubeType',
    placeholder: '',
    singleSelectLabel: 'langkey.tube-type-tubeless',
    pluralSelectLabel: 'langkey.tube-type-tubeless',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    searchOnSelect: true,
    sortable: true,
    sortFunction: (a, b) => a.label.toString().length - b.label.toString().length,
  },
  {
    key: 'manufacturer',
    filterKey: 'manufacturers',
    placeholder: '',
    singleSelectLabel: 'langkey.manufacturer',
    pluralSelectLabel: 'langkey.manufacturers',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    searchOnSelect: true,
  },
  {
    key: 'profile',
    filterKey: 'profile',
    placeholder: '',
    singleSelectLabel: 'langkey.profile',
    pluralSelectLabel: 'langkey.profiles',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    searchOnSelect: true,
  },
  {
    key: 'quantity',
    filterKey: 'minStock',
    placeholder: '',
    singleSelectLabel: 'langkey.quantity',
    pluralSelectLabel: 'langkey.quantities',
    single: true,
    isUseLabelInSelectedValue: true,
    searchOnSelect: true,
    isSearchSubmit: true,
    customOptions: [
      {
        label: 'langkey.stockmorethan2',
        value: 2,
      },
      {
        label: 'langkey.stockmorethan4',
        value: 4,
      },
      {
        label: 'langkey.stockmorethan6',
        value: 6,
      },
      {
        label: 'langkey.stockmorethan8',
        value: 8,
      },
      {
        label: 'langkey.stockmorethan10',
        value: 10,
      },
    ],
  },
  {
    key: 'usage',
    filterKey: 'usage',
    placeholder: '',
    singleSelectLabel: 'langkey.usage',
    pluralSelectLabel: 'langkey.usages',
    requiredValueBefore: ['widths', 'aspectRatios', 'diameters'],
    single: true,
    searchOnSelect: true,
    isUseLabelInSelectedValue: true,
  },
];

export const attributeFilterFields = [
  {
    filterKey: 'retreaded',
    filterValue: 'retreaded',
    area: 'retreaded',
    value: 'langkey.filter-retread',
    isBoolean: true,
  },
];
